import * as Sentry from '@sentry/browser'
import config from 'config'


function nodeToString(node, withContent) {
  const attributes = [ 'data-testid', 'aria-label', 'id', 'class' ]

  try {
    let result = node.tagName.toLowerCase()

    attributes.some(function (attribute) {
      const value = node.getAttribute(attribute)

      if (!value) {
        return false
      }

      if (attribute === 'id') {
        result += '#' + value
        return true
      }

      if (attribute === 'class') {
        result += '.' + value.replace(/\s+/g, '.')
        return true
      }

      result += '[' + attribute + '="' + value + '"]'
      return true
    })

    if (withContent) {
      const text = node.innerText

      if (text.length && text.length < 50) {
        result += ' "' + text + '"'
      }
    }

    return result
  }
  catch (error) {
    return 'Unknown'
  }
}

function htmlTreeToString(node) {
  try {
    let current = node
    const parts = []
    let depth = 0
    const maxDepth = 5

    while (current && depth < maxDepth) {
      const part = nodeToString(current, depth === 0)

      parts.push(part)
      depth++
      current = current.parentNode
    }

    parts.reverse()

    return parts.join(' > ')
  }
  catch (error) {
    return 'Unknown'
  }
}

if (config.sentryClientDSN) {
  Sentry.init({
    dsn: config.sentryClientDSN,
    release: config.appVersion,
    environment: config.runEnv,
    normalizeDepth: 15,
    // debug: true,
    integrations: [
      // Add custom 'GlobalHandlers' without 'onunhandledrejection'
      Sentry.globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: false,
      }),
      Sentry.breadcrumbsIntegration({
        // disable console breadcrumbs, because we add them automatically in logger
        console: false,
      }),
    ],
    allowUrls: [
      /.+\.scentbird\.com/,
      'localhost:5000',
    ],

    beforeSend(event, hint) {
      const error = hint.originalException as Error
      const message = typeof error === 'string' ? error : error?.message || ''

      // combine different chunk errors into one event
      if (message.match(/loading.*chunk/i)) {
        event.fingerprint = [ 'ChunkLoadError' ]
      }
      else if (message.match(/p2sq/)) {
        event.fingerprint = [ 'GTM' ]
      }
      else if (message.match(/network error/i)) {
        event.fingerprint = [ 'NetworkError' ]
      }

      return event
    },

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click' || breadcrumb.category === 'ui.input') {
        const target = hint.event.target

        if (target) {
          // replace message with message with testids
          breadcrumb.message = htmlTreeToString(target)
        }
      }

      return breadcrumb
    },
  })
}

// enrich event data
const scope = Sentry.getCurrentScope()
scope.addEventProcessor((event) => {
  if (typeof window.dumpData === 'function') {
    const initialData = window.dumpData()

    event.contexts = {
      initialData,
      ...event.contexts,
    }
  }

  return event
})
